import Modal from '@shared/common/Modal';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { WorkspaceMembersForUnit } from '@entities/models/workspace';
import { MemberListItem } from './MemberListItem';
import { InviteByEmail } from './InviteByEmail';
import {
  getMembersForUnit as getDocumentMembers,
  modifyUserAccessLevel,
} from '@app/services/share.service';
import './sharedoc.styles.css';
import { AccessTypes } from './types';
import { successToastOptions, errorToastOptions } from '@shared/common/Toast';
import { track } from '@amplitude/analytics-browser';
import { useDispatch, useSelector } from 'react-redux';
import {
  getModalState,
  updateShareModalState,
} from '@app/redux/features/modalsSlice';
import { ShareDocViaLink } from './ShareDocViaLink';

export function ShareDoc() {
  const dispatch = useDispatch();
  const [members, setMembers] = useState<WorkspaceMembersForUnit>([]);

  const { isOpen, title, unitId } = useSelector(getModalState).shareModalState;

  useEffect(() => {
    if (isOpen) {
      track('document_share_popup', { option: 'share' });
    }
  }, [isOpen]);

  useEffect(() => {
    async function getMembers() {
      if (!unitId) return;
      const res = await getDocumentMembers(unitId);
      setMembers(res ?? []);
    }
    getMembers();
  }, [unitId, isOpen]);

  const handleEditMember = (access: AccessTypes, id: string) => {
    if (!unitId) return;
    try {
      modifyUserAccessLevel(access, id, unitId);
      toast.success('Access level successfully changed!', successToastOptions);
    } catch (e) {
      toast.error('Unable change access level', errorToastOptions);
    }
  };

  const handleClose = () => {
    track('document_share_close');
    dispatch(
      updateShareModalState({
        isOpen: false,
        title: title,
        unitId: '',
      }),
    );
  };

  return (
    <Modal
      title={title ? title : 'Share doc'}
      closeModal={handleClose}
      modalIsOpen={isOpen}
      wrapChildren={false}
      userClassName='w-638 !p-10 !animate-none'
      titleClassName='!text-20-16 !font-medium'
      closeBtnClassName='absolute top-6 right-6'
    >
      <div
        className='flex flex-col full-width mt-6 z-0'
        contentEditable={false}
      >
        <InviteByEmail unitId={unitId} />
        <ul>
          {members?.map((member) => (
            <li key={member.user.id || member.user.name}>
              <MemberListItem
                onChange={handleEditMember}
                imageSrc={member.user.image}
                name={member.user.name}
                email={member.user.email}
                memberId={member.user.id}
                role={member.role}
                access={member.access}
              />
            </li>
          ))}
        </ul>
        <div className='width-full border  border-solid border-text10 my-5' />
        <ShareDocViaLink />
      </div>
    </Modal>
  );
}
