import styles from './style.module.scss';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import { ReactComponent as Everything } from '@images/everything.svg';
import { ReactComponent as ArrowDown } from '@images/arrowDownSmall.svg';
import { ReactComponent as DocumentIcon } from '@images/document.svg';
import { ReactComponent as WhiteboardIcon } from '@images/whiteboard.svg';
import { ReactComponent as Document } from '@images/document.svg';
import TreeView, { INode, flattenTree } from 'react-accessible-treeview';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { Unit, UnitType } from '@entities/models/unit';
import { IFlatMetadata } from 'react-accessible-treeview/dist/TreeView/utils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createDocument } from '@app/services/document.service';
import _ from 'lodash';
import ComingSoon from '@widgets/components/ComingSoon';
import { track } from '@amplitude/analytics-browser';
import CreateChannel from '@widgets/components/Modals/CreateChannel/CreateChannel';
import { createWhiteboard } from '@app/services/whiteboard.service';
import { useUnitsContext } from '@app/context/unitsContext/unitsContext';
import Tooltip from '@shared/uikit/tooltip';
import IconButton from '@shared/uikit/icon-button';
import { usePermissionsContext } from '@app/context/permissionsContext/permissionsContext';
import { UnitActionListBtn } from '@widgets/unit-action-lists/UnitActionListBtn';
import { Plus } from 'lucide-react';
import ActiveElement from '@shared/uikit/active-element';
import { Popover, PopoverContent, PopoverTrigger } from '@shared/uikit/popover';
import ActiveButton from '@shared/uikit/active-button';

type TreeNode = {
  id: string;
  name: string;
  children: TreeNode[];
};

const traverseTreeInDepth = function traverse<
  Node extends { children: Node[] },
>(
  tree: Node[],
  callback: (node: Node, stackTrace: Node[], breakLoop: () => void) => void,
  _stackTrace: Node[] = [],
) {
  let interrupted = false;
  const breakLoop = () => {
    interrupted = true;
  };

  for (const node of tree) {
    if (interrupted) break;
    const currentStack = [..._stackTrace, node];
    callback(node, currentStack, breakLoop);
    if (node.children) {
      traverse(node.children, callback, currentStack);
    }
  }
};

const buildUnitsTree = function buildTree(
  units: Unit[],
  _parentUnitId?: string | null,
): TreeNode[] {
  const childs = _parentUnitId
    ? units.filter(
        (unit) =>
          unit.parentUnit &&
          unit.parentUnit.id === _parentUnitId &&
          unit.type !== 'task_board',
      )
    : units.filter(
        (unit) => unit.parentUnit === null && unit.type !== 'task_board',
      );
  return childs.map((unit: Unit) => {
    return {
      id: unit.id,
      name: unit.name,
      children: buildTree(units, unit.id),
    };
  });
};

const SidebarUnitsPanel = () => {
  const { units, addUnit } = useUnitsContext();
  const { documentId } = useParams();
  const navigate = useNavigate();
  const [isCreateChannelModalOpen, setCreateChannelModalState] =
    useState<boolean>(false);
  const activeUnit = units.find((unit) => unit.id === documentId);

  const unitsTree = useMemo(() => buildUnitsTree(units), [units]);
  const unitsFaltten = useMemo(
    () => flattenTree({ name: '', children: unitsTree }),
    [unitsTree],
  );

  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const validExpandedIds = useMemo(() => {
    const idsSet = new Set(expandedIds);
    return unitsFaltten.reduce<string[]>((acc, unit) => {
      const unitId = unit.id.toString();
      if (idsSet.has(unitId)) acc.push(unitId);
      return acc;
    }, []);
  }, [unitsFaltten, expandedIds]);

  const getIcon = (element: INode<IFlatMetadata>) => {
    const unit = units.find((unit) => unit.id === element.id);
    const type = unit?.type ?? 'default';
    const color = unit?.color ?? '#719eff88';
    switch (type) {
      case 'document':
        return <DocumentIcon />;
      case 'channel':
        return (
          <ColoredIcon
            letter={element.name[0].toUpperCase()}
            color={color}
          />
        );
      case 'whiteboard':
        return <WhiteboardIcon />;
      default:
        <ColoredIcon
          letter={element.name[0].toUpperCase()}
          color={color}
        />;
    }
  };

  const { canEditUnit } = usePermissionsContext();

  const openUnit = (id: string) => {
    navigate(`/workspace/${id}`);
  };

  const handleCreateSubDocument = async (event: any, unit: Unit) => {
    const newDocumentId = uuidv4();
    const newUnit = await createDocument(unit.id, newDocumentId);
    if (newUnit) {
      addUnit(newUnit);
      openUnit(newUnit.id);
    }
  };

  const handleCreateChannel = () => {
    track('create_channel_popup_opened');
    setCreateChannelModalState(true);
  };

  const handleExpand = (event: MouseEvent<HTMLButtonElement>) => {
    const isExpanded = Boolean(Number(event.currentTarget.dataset.expanded));
    const elementId = event.currentTarget.dataset.id;
    if (!elementId) return;
    setExpandedIds((ids) => {
      const idsSet = new Set(ids);
      if (isExpanded) idsSet.delete(elementId);
      else idsSet.add(elementId);
      return Array.from(idsSet);
    });
  };

  const createUnit = async (type: UnitType, channelId: string) => {
    const id = uuidv4();
    const newUnit = await getCreateQuery(type, channelId, id);
    if (newUnit) {
      await addUnit(newUnit);
      openUnit(newUnit.id);
    }
  };

  const getCreateQuery = (type: UnitType, channelId: string, id: string) => {
    switch (type) {
      case 'document':
        return createDocument(channelId, id);
      case 'whiteboard':
        return createWhiteboard(channelId, id);
      default:
        return createDocument(channelId, id);
    }
  };

  const getActions = (element: INode<IFlatMetadata>) => {
    const unit = units.find((unit) => unit.id === element.id);
    const [openUnit, setOpenUnit] = useState<string | null>(null);
    if (!unit) return null;

    const isChannel = unit.type === 'channel';

    const handleClose = () => setOpenUnit(null);

    const handleOpen = () => {
      const elementId = element.id.toString();
      if (elementId === openUnit) handleClose();
      else setOpenUnit(elementId);
    };

    const handleCreateUnit = (event: MouseEvent<HTMLButtonElement>) => {
      const unitType = event.currentTarget.dataset.unitType;
      if (!unitType) return;
      createUnit(unitType as UnitType, unit.id);
      handleClose();
    };

    const canAddSubDoc = canEditUnit(unit.id);
    let actionsRenderer = null;

    if (isChannel) {
      actionsRenderer = (
        <Popover
          open={!!openUnit}
          onOpenChange={handleOpen}
        >
          <Tooltip
            placement='right'
            content='Create Doc, Whiteboard'
          >
            <PopoverTrigger asChild>
              <IconButton aria-label='Create a document, whiteboard'>
                <Plus />
              </IconButton>
            </PopoverTrigger>
          </Tooltip>
          <PopoverContent
            autoFocusContent
            alignOffset={-6}
            sideOffset={14}
            className='min-w-[240px]'
            align='end'
            side='bottom'
          >
            <div className='p-2'>
              <ActiveButton
                data-unit-type='document'
                leftSection={<Document />}
                onClick={handleCreateUnit}
              >
                New doc
              </ActiveButton>
              <ActiveButton
                data-unit-type='whiteboard'
                leftSection={<WhiteboardIcon />}
                onClick={handleCreateUnit}
              >
                New whiteboard
              </ActiveButton>
            </div>
          </PopoverContent>
        </Popover>
      );
    } else {
      actionsRenderer = (
        <>
          <UnitActionListBtn
            variant='filled'
            unit={unit}
            onOpen={handleOpen}
          />
          {canAddSubDoc && (
            <IconButton
              aria-label='Create a subdocument'
              onClick={(e) => handleCreateSubDocument(e, unit)}
            >
              <Plus />
            </IconButton>
          )}
        </>
      );
    }

    return (
      <div
        className={cn(
          styles.sidebarUnitsPanelTreeNodeActions,
          openUnit === element.id || isChannel
            ? styles.showSubMenu
            : styles.hideSubMenu,
        )}
      >
        {actionsRenderer}
      </div>
    );
  };

  useEffect(() => {
    traverseTreeInDepth(unitsTree, (node, stackTrace, breakLoop) => {
      if (node.id !== documentId) return;
      setExpandedIds((ids) => {
        const idsSet = new Set(ids);
        stackTrace.forEach((element) => idsSet.add(element.id));
        return Array.from(idsSet);
      });
      breakLoop();
    });
  }, [unitsTree, documentId]);

  useEffect(() => {
    if (!activeUnit) return;
    setSelectedIds([activeUnit.id]);
  }, [activeUnit]);

  return (
    <div className={styles.sidebarUnitsPanel}>
      <div className={styles.sidebarUnitsPanelRow}>
        <span className={styles.sidebarUnitsPanelChannelTitle}>Channels</span>
        <Tooltip
          placement='right'
          content='Create a Channel'
        >
          <IconButton
            aria-label='Create a channel'
            onClick={handleCreateChannel}
          >
            <Plus />
          </IconButton>
        </Tooltip>
        <CreateChannel
          handleClose={() => {
            track('channel_create_closed');
            setCreateChannelModalState(false);
          }}
          isOpen={isCreateChannelModalOpen}
        />
      </div>
      <div className={styles.sidebarUnitsPanelRowDisable}>
        <Everything />
        Everything
        <ComingSoon right={40} />
      </div>
      <TreeView
        data={unitsFaltten}
        className={styles.sidebarUnitsPanelTree}
        selectedIds={selectedIds}
        expandedIds={validExpandedIds}
        clickAction='FOCUS'
        nodeRenderer={({
          element,
          getNodeProps,
          level,
          isSelected,
          isExpanded,
          isBranch,
        }) => {
          const { onClick: _, ...nodeProps } = getNodeProps();
          return (
            <div
              {...nodeProps}
              style={{ paddingLeft: 24 * (level - 1) }}
            >
              <ActiveElement
                variant={
                  isSelected || element.id === documentId
                    ? 'active'
                    : 'transparent'
                }
                size='l'
              >
                {({ className }) => (
                  <div
                    className={cn(
                      'flex items-center w-full',
                      styles.sidebarUnitsPanelTreeNode,
                      className,
                    )}
                  >
                    {isBranch && (
                      <button
                        aria-expanded={isExpanded}
                        data-expanded={Number(isExpanded)}
                        data-id={element.id}
                        className='w-4 h-4 flex absolute left-[4px] items-center justify-center rounded-md'
                        onClick={handleExpand}
                      >
                        <ArrowDown
                          className={cn({
                            [styles.sidebarUnitsPanelTreeNodeArrowExpanded]:
                              isExpanded,
                            [styles.sidebarUnitsPanelTreeNodeArrowNotExpanded]:
                              !isExpanded,
                          })}
                        />
                      </button>
                    )}
                    <Link
                      className='flex items-center h-full w-full overflow-hidden'
                      to={`workspace/${element.id.toString()}`}
                    >
                      {getIcon(element)}
                      <span
                        className={cn(styles.sidebarUnitsPanelTreeNodeTitle)}
                      >
                        {element.name}
                      </span>
                    </Link>
                    {getActions(element)}
                  </div>
                )}
              </ActiveElement>
            </div>
          );
        }}
      />
    </div>
  );
};

export default SidebarUnitsPanel;

const ColoredIcon = ({ letter, color }: { letter: string; color: string }) => {
  return (
    <div
      className={styles.sidebarUnitsPanelColoredIcon}
      style={{
        backgroundColor: color,
        color:
          parseInt(color.replace('#', ''), 16) > 0xffffff / 2 ? '#000' : '#fff',
      }}
    >
      <span>{letter}</span>
    </div>
  );
};
