import { useMemo } from 'react';
import { ActiveElementProps, ElementSize, ElementVariant } from './props';

const heightSizes: Record<ElementSize, string> = {
  l: 'h-9',
  m: 'h-7',
  s: 'h-6',
  xs: 'h-5',
};

const widthSizes: Record<ElementSize, string> = {
  l: 'w-9',
  m: 'w-7',
  s: 'w-6',
  xs: 'w-5',
};

const fontSizes: Record<ElementSize, string> = {
  l: 'text-sm',
  m: 'text-sm',
  s: 'text-xs',
  xs: 'text-xs',
};

const colors: Record<ElementVariant, string> = {
  filled: `
    bg-text10 hover:bg-text20 focus:bg-text20 active:bg-text20 focus-within:bg-text20
    text-text50 hover:text-text60 focus:text-text60 active:text-text60 focus-within:text-text60
  `,
  transparent: `
    hover:bg-backgroundHover focus:bg-backgroundHover active:bg-backgroundHover focus-within:bg-backgroundHover
    text-text70
  `,
  active: `
    bg-backgroundActive
    text-fontBlue
  `,
  error: `
    hover:bg-backgroundHover focus:bg-backgroundHover active:bg-backgroundHover focus-within:bg-backgroundHover
    text-errorText
  `,
};

const getStyles = (
  size: ElementSize,
  variant: ElementVariant,
  isIcon?: boolean,
): { width: string; height: string; fontSize: string; colors: string } => {
  const height = heightSizes[size];
  const width = isIcon ? widthSizes[size] : '';
  const fontSize = fontSizes[size];
  const _colors = colors[variant];

  return { width, height, fontSize, colors: _colors };
};

export function useStyles(
  props: Pick<ActiveElementProps, 'isIcon' | 'className'> &
    Pick<Required<ActiveElementProps>, 'size' | 'variant'>,
) {
  return useMemo(
    () => getStyles(props.size, props.variant, props.isIcon),
    [props],
  );
}
