import { useEffect, useState } from 'react';
import Button from '@shared/common/Button';
import { MemberItem } from './MemberItem';
import { GuestItem } from './GuestItem';
import MemberInvite from './MemberInvite';
import toast from 'react-hot-toast';
import { errorToastOptions, successToastOptions } from '@shared/common/Toast';
import { track } from '@amplitude/analytics-browser';
import {
  changeUserWorkspaceRole,
  transferWorkspaceOwnership,
  deleteUserFromWorkspace,
} from '@app/services/workspace.service';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '@app/redux/features/userSlice';
import { useGetWorkspaceMembers } from '@app/queries/workspace/user/useGetWorkspaceMembers';
import { useGetWorkspaceGuests } from '@app/queries/workspace/user/useGetWorkspaceGuests';
import { Plus } from 'lucide-react';

type Tabs = 'members' | 'guests';

const MembersSettings = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<Tabs>('members');
  const currentUser = useSelector(selectCurrentUser);

  const { activeMembers: members, refetch: refetchMembers } =
    useGetWorkspaceMembers({
      workspaceId: String(currentUser.workspace?.id),
      enabled: !!currentUser.workspace?.id,
    });

  const { activeGuests: guests, refetch: refetchGuests } =
    useGetWorkspaceGuests({
      workspaceId: String(currentUser.workspace?.id),
      enabled: !!currentUser.workspace?.id,
    });

  useEffect(() => {
    track('members_settings_opened');
  }, []);

  if (!currentUser.workspace?.id) {
    console.error(
      'Workspace Id is not defined. Unable to retrieve workspace members. Try to log out, cleanup cookies and local storage, and log in again.',
    );
  }

  const createTransferOwnershipCb = (futureOwnerId: string) => async () => {
    if (!currentUser.workspace) {
      return;
    }

    try {
      await transferWorkspaceOwnership({
        workspaceId: currentUser.workspace?.id,
        userId: futureOwnerId,
      });

      refetchMembers();
      refetchGuests();

      toast.success('Access level successfully changed!', successToastOptions);
    } catch (e) {
      toast.error('Unable change access level', errorToastOptions);
    }
  };

  const createGuestToMemberCb = (guestId: string) => async () => {
    if (!currentUser.workspace) {
      return;
    }

    try {
      await changeUserWorkspaceRole({
        workspaceId: currentUser.workspace?.id,
        userId: guestId,
        role: 'member',
      });

      refetchMembers();
      refetchGuests();

      toast.success('Access level successfully changed!', successToastOptions);
    } catch (e) {
      toast.error('Unable change access level', errorToastOptions);
    }
  };

  const handleMemberDelete = async (id: string) => {
    const listToSearchItem = activeTab === 'members' ? members : guests;

    const itemToDelete = listToSearchItem.find((x) => x.user.id === id);

    if (!itemToDelete || !currentUser.workspace) {
      return;
    }

    try {
      deleteUserFromWorkspace(currentUser.workspace.id, id);
      const successLabel = itemToDelete?.user?.name
        ? `The user '${itemToDelete?.user?.name} (${itemToDelete.user.email})' has been deleted`
        : `The user '${itemToDelete.user.email}' has been deleted`;

      toast.success(successLabel, successToastOptions);
      refetchMembers();
    } catch (error) {
      const errorLabel = itemToDelete?.user?.name
        ? `The user '${itemToDelete.user.name} (${itemToDelete.user.email})' has not been deleted`
        : `The user '${itemToDelete.user.email}' has not been deleted`;
      toast.error(errorLabel, errorToastOptions);
    }
  };

  return (
    <>
      <MemberInvite
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
      <div className='flex justify-between items-center'>
        <ul className='flex'>
          <li
            className={`text-2xl3 ${
              activeTab === 'members' ? 'text-text90' : 'text-text50'
            } mr-10 relative flex items-center`}
          >
            <button onClick={() => setActiveTab('members')}>
              Members ({members.length})
            </button>
          </li>
          <li
            className={`text-2xl3 ${
              activeTab === 'guests' ? 'text-text90' : 'text-text50'
            } mr-10 relative flex items-center`}
          >
            <button onClick={() => setActiveTab('guests')}>
              Guests ({guests.length})
            </button>
          </li>
        </ul>
        <Button
          label='Invite'
          styleType='small-primary'
          onClick={() => {
            track('members_settings_invite_action');
            setModalIsOpen(true);
          }}
          className='pl-3'
          icon={<Plus className='mr-1.5 [&>path]:stroke-white' />}
        />
      </div>
      <div className='mt-9 border-t border-text10'>
        {activeTab === 'members' &&
          members.map((item) => (
            <MemberItem
              id={item.user.id}
              key={item.user.email}
              name={item.user.name}
              email={item.user.email}
              role={item.role === 'owner' ? 'owner' : 'member'}
              workspaceId={currentUser.workspace?.id}
              handleChange={createTransferOwnershipCb(item.user.id)}
              handleDelete={handleMemberDelete}
            />
          ))}
        {activeTab === 'guests' &&
          guests.map((item) => (
            <GuestItem
              id={item.user.id}
              key={item.user.email}
              name={item.user.name}
              email={item.user.email}
              workspaceId={currentUser.workspace?.id}
              handleChange={createGuestToMemberCb(item.user.id)}
              handleDelete={handleMemberDelete}
            />
          ))}
      </div>
    </>
  );
};

export default MembersSettings;
