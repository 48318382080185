import ActiveButton from '@shared/uikit/active-button';
import { ReactComponent as Logo } from '@images/logo.svg';

type WhatsBtnProps = {
  callback?: any;
};

const WhatsButton = ({ callback }: WhatsBtnProps) => {
  const handleClick = async () => {
    callback && callback();
  };

  return (
    <ActiveButton
      leftSection={
        <Logo />}
      onClick={handleClick}
    >
      What’s Dokably?
    </ActiveButton>
  );
};

export default WhatsButton;
